import React from "react";

export default function Upload() {
  return (
    <div className="container px-6 mx-auto">
      <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
        <div className="flex flex-col w-full">
          <h1 className="text-5xl text-gray-800 font-bold">
            Secure File Transfer
          </h1>
          <p className="mt-4 md:w-10/12 lg:w-6/12 mx-auto md:mx-0 text-gray-500">
            Use this page to securely send files to Velo when requested.
            <br />
            <br />
            The link provided to you is unique to your Velo profile and should
            not be shared with others. Files may be uploaded one at a time.
          </p>
          <p className="mt-4 md:w-10/12 lg:w-6/12 mx-auto md:mx-0 text-gray-500">
            <strong>
              You can only upload using this service if you provide a transfer
              session id.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}
