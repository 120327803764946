import { Routes, Route, BrowserRouter } from "react-router-dom";
import Upload from "./views/Upload";
import About from "./views/About";
import Error from "./views/Error";

export default function Example() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="transfer/:id" element={<Upload />} />
        <Route path="error" element={<Error />} />
        <Route index element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}
