import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Lock from "../icons/Lock";
import { useMutation } from "react-query";
import client from "../services/api";

export default function Upload() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [response, setResponse] = useState("");
  const [dataURL, setDataURL] = useState("");
  const transferFileMutation = useMutation(
    ({ id, payload }) => {
      return client.post(`/v1/upload/${id}`, payload);
    },
    {
      onError: (error, variables, context) => {
        setResponse("error");
      },
      onSuccess: (data, variables, context) => {
        setResponse("success");
      },
      onSettled: () => {
        document.querySelector("input[type=file]").value = null;
        setLoadingData(false);
      },
    }
  );

  const onSubmit = (_) => {
    setDataURL("");
    setResponse("");
    setLoadingData(true);

    const file = document.querySelector("input[type=file]").files[0];
    const reader = new FileReader();

    const handler = () => {
      // convert file to base64 string
      setDataURL(reader.result);

      const payload = {
        filename: file.name,
        data: reader.result,
      };

      transferFileMutation.mutate({ id, payload });
    };

    reader.onload = handler;
    reader.readAsDataURL(file);
  };

  return (
    <div className="container px-6 mx-auto">
      <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
        <div className="flex flex-col w-full">
          <h1 className="text-5xl text-gray-800 font-bold">
            Secure File Transfer
          </h1>
          <p className="mt-4 md:w-10/12 lg:w-6/12 mx-auto md:mx-0 text-gray-500">
            Use this page to securely send files to Velo when requested.
            <br />
            <br />
            The link provided to you is unique to your Velo profile and should
            not be shared with others. Files may be uploaded one at a time.
          </p>
        </div>
        <div className="w-full md:w-full lg:w-9/12 mx-auto md:mx-0">
          <div className="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
            <h2 className="text-2xl font-bold text-gray-800 text-left mb-1">
              <Lock className="inline-block -ml-3" /> File Upload
            </h2>
            <form
              action=""
              className="w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div id="input" className="flex flex-col w-full my-5">
                {errors.file && (
                  <div role="alert">
                    <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                      Error
                    </div>
                    <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 mb-4">
                      <p>
                        {errors?.file?.type === "lessThan200MB" &&
                          "File is too large (Max 200MB)"}
                      </p>
                    </div>
                  </div>
                )}

                {response === "error" && (
                  <div role="alert">
                    <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                      Error
                    </div>
                    <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 mb-4">
                      <p>
                        We were unable to transfer the file. Please try again.
                      </p>
                    </div>
                  </div>
                )}

                {response === "success" && (
                  <div role="alert">
                    <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                      Success
                    </div>
                    <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 mb-4">
                      <p>File was uploaded.</p>
                    </div>
                  </div>
                )}

                <label
                  htmlFor="file"
                  className="text-gray-500 mb-2 text-left hidden"
                >
                  File
                </label>

                <input
                  type="file"
                  id="file"
                  placeholder="Please choose a file"
                  className=" border-2 border-gray-100 rounded-lg px-4 py-3 bg-white bg-clip-padding
                  form-control
                  border-solid
                  transition
                  ease-in-out
                  block w-full
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none focus:shadow-lg file:mr-5 file:py-2 file:px-6
                  file:rounded-full file:border-0
                  file:text-sm file:font-medium
                  file:bg-blue-50 file:text-secureupload
                  hover:file:cursor-pointer hover:file:bg-amber-50
                  hover:file:text-amber-700"
                  {...register("file", {
                    onChange: () => setResponse(""),
                    validate: {
                      lessThan200MB: (files) =>
                        files[0]?.size < 210000000 || "Max 200MB",
                    },
                  })}
                />
              </div>
              <div id="button" className="flex flex-col w-full my-5">
                <input
                  type="submit"
                  className="w-full py-4 bg-secureupload rounded-lg text-blue-100 cursor-pointer"
                  value="Secure Transfer"
                />
              </div>
              {loadingData && (
                <div class="flex space-x-2">
                  <span className="">Loading...</span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
