import axios from "axios";
import { addMocks } from "./mock-api";

let client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 300000,
  maxRedirects: 0,
});

if (!process.env.REACT_APP_API_HOST) {
  client = addMocks(client);
}

export default client;
